export const questionnaireItemTypes = [
  'group',
  'display',
  'boolean',
  'decimal',
  'integer',
  'date',
  'dateTime',
  'time',
  'string',
  'text',
  'url',
  'choice',
  'reference',
  'quantity',
];

export const questionnaireItemTypesWithoutGroup = questionnaireItemTypes.slice(1);

export const questionnaireItemsWithOptions = ['choice'];

export const isCodingTypeElementWithAtLeastTwoOptions = (element) =>
  element?.options?.length < 2 && element?.type === questionnaireItemsWithOptions[0];
